import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import {history} from "../../../shared/configure-store";
import Lightbox from 'react-image-lightbox';

import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";
import people from "../../../images/pak-alif.png";

require('dotenv').config();

class Services extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false,
            images: [photo1,photo2],
            photoIndex: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - Home';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner service">
                    <div className='container'>
                        <div className='row '>
                            <div className='col-md-10'>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                <div className='content text-left'>
                                    <h1>SERVICES</h1>
                                    <p>Maritime incident investigations involve a complex interaction of many causes. Identifying these causes requires consideration, not only of the clear information gleaned from reports and witness statements, photographs, and wreckage, but also the impact of aspects of the organization of work, and human factors such as perception, motivation, and behavior. The task of a Marine Surveyor involved in an incident investigation is to determine what these factors and faults are and the links between them.</p>
                                </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-services">
                    <div className='container'>
                        <div className='title-wrap text-right'>
                            <h2>CONDITION SURVEY</h2>
                            <div className='border-line ml-auto'> </div>
                        </div>
                        {this.state.isOpen && (
                            <Lightbox
                                mainSrc={this.state.images[this.state.photoIndex]}
                                nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                                prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                                    })
                                }
                            />
                        )}
                        <div className='row'>

                            <div className='col-md-4'>
                                <div className='card-image card1'>
                                    <div className='detail'>
                                        <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='card-image card1'>
                                    <div className='detail'>
                                        <h3>VESSEL CONDITION SURVEYOR</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='card-image card1'>
                                    <div className='detail'>
                                        <h3>MARINE PRE-PURCHASE SURVEY</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='card-image card1'>
                                    <div className='detail'>
                                        <h3>CONDITION SURVEY FOR UNDERWRITERS</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='card-image card1'>
                                    <div className='detail'>
                                        <h3>ON / OFF HIRE SURVEYS.</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='card-image card1'>
                                    <div className='detail'>
                                        <h3>SUPERVISION AND REPAIR MANAGEMENT</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='card-image card1'>
                                    <div className='detail'>
                                        <h3>WARRANTY SURVEY</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='card-image card1' onClick={() => this.setState({ isOpen: true })}>
                                    <div className='detail'>
                                        <h3>YACHT & PHINISI SURVEY</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                               </div>
                            </div>


                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default Services;
