import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import {history} from "../../../shared/configure-store";
import section1 from "../../../images/section1.jpeg";
import section2 from "../../../images/section2.jpg";
import people from "../../../images/pak-alif.JPG";

import SimpleReactValidator from "simple-react-validator";

require('dotenv').config();

class Home extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,
            showDialog: false,
            errors: {},

            orderBy: '',
            sortedBy: '',
            searchBy: '',

            value: 0,

            videos: [],
            banners: [],
            galleries: [],

            total: 0,
            perPage: 20,
            currentPage: 0,
            nextPage: 0,
            currentPageTable: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - Home';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner home">
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-10 col-lg-8'>
                                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
                                <div className='content'>
                                    <h1>Marine Surveyor & Consultant</h1>
                                    <p>We support the industry with professional representation to Clients for incident investigations, expert witness, and marine survey inspections. </p>

                                </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="section1">
                    <div className='container'>
                        <div className='row align-items-center flex-md-row-reverse'>
                            <div className='col-md-6'>
                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} animateIn="animate__fadeIn" >
                                    <div className='img-wrap'>
                                        <img src={section1} className='img-fluid' />
                                    </div>

                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-6'>
                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} animateIn="animate__fadeInUp">
                                <h2>WHO WE ARE</h2>
                                </AnimationOnScroll>

                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} delay={500} duration={4} animateIn="animate__fadeIn" >
                                <p>Abadi Cemerlang is recognized in Indonesia insurance market as one of the leading independent Marine Surveyors, and for more than 20 years served as a member of the P&I Club and also the representative for overseas underwriters.</p>
                                <p>Abadi Cemerlang has been engaged to examine various incidents and disputes including fatality investigations, large-scale equipment damage and pollution claims, and contractual disputes in the Insurance market.</p>

                                {/*<p>Maritime incident investigations involve a complex interaction of many causes. Identifying these causes requires consideration, not only of the clear information gleaned from reports and witness statements, photographs, and wreckage, but also the impact of aspects of the organization of work, and human factors such as perception, motivation, and behavior. The task of a Marine Surveyor involved in an incident investigation is to determine what these factors and faults are and the links between them.</p>*/}

                                <p>There are many examples of cases being lost for simple reasons, which may occur as a result of an inadequate investigation undertaken, significant evidence overlooked, and unsubstantiated causes of incident suggested.</p>

                                <p>At Abadi Cemerlang, we ensure a robust and professional investigation and concise report that will withstand external review</p>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section2 section-services">
                    <div className='container'>
                        <AnimationOnScroll initiallyVisible={true} animateOnce={true} animateIn="animate__fadeInRight" >
                        <h2>Hull Machinery and P&I </h2>
                        </AnimationOnScroll>

                        <AnimationOnScroll initiallyVisible={false} delay={500} animateOnce={true} animateIn="animate__fadeIn" >
                            <div className='row '>
                                <div className='col-md-4'>
                                    <div className='card-image cat1'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat2'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat3'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat4'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat5'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat6'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat7'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat8'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card-image cat9'>
                                        <div className='detail'>
                                            <h3>VESSEL CONDITION SURVEY FOR P&I dan H&M</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AnimationOnScroll>

                    </div>
                </section>
                <section className="section3">
                    <div className='container'>
                        <div className='row align-items-center'>

                            <div className='col-md-5'>
                                <AnimationOnScroll initiallyVisible={false} animateOnce={true} animateIn="animate__fadeIn" >
                                <div className='img-wrap'>
                                    <img src={people} className='img-fluid' />

                                </div>
                                <p className='mb-0 mt-3'>Alif Hastono</p>
                                <small>Director / Principal Surveyor</small>
                                </AnimationOnScroll>
                            </div>
                            <div className='col-md-7 text-md-right'>
                                <AnimationOnScroll initiallyVisible={false} delay={500} animateOnce={true} animateIn="animate__fadeIn" >
                                <h2>THE A-TEAM</h2>

                                <blockquote>"Every project is unique in the resources it requires. In order to deliver successful results we adopt a flexible manning arrangement and apply personnel where necessary."</blockquote>

                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.9184359831665!2d106.72336331543245!3d-6.274454895458601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f0764c2ed52d%3A0xe88d52e3c636702a!2sPT%20Abadi%20Cemerlang!5e0!3m2!1sid!2sid!4v1588624271359!5m2!1sid!2sid"
                    width="100%" height="250" frameBorder="0" allowFullScreen="" aria-hidden="false"
                    tabIndex="0">

                </iframe>

                </section>
            </div>

        )
    }
}

export default Home;
