import React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './shared/configure-store'

import NotFound from "./components/container/not-found"
import { PrivateRoute, RedirectRoute } from './libraries/route-handling'
import Navbar from "./components/container/navbar";
import Footer from "./components/container/footer";
import Home from "./components/container/homes";
import Services from "./components/container/services";

const Routes = ({store}) => (

    <Provider store={store}>

        <ConnectedRouter history={history}>
            <Navbar />
            <main>
                {

                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/our-services" component={Services}/>

                        <Route component={NotFound}/>

                    </Switch>

                }
            </main>
            <Footer />

        </ConnectedRouter>

    </Provider>

);

export default Routes;