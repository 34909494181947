import React,{ Component } from 'react';
import Logo from '../../../images/logo.png';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {connect} from "react-redux";

class Footer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            open: false,
            fix: false,
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <footer className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <img src={Logo} className='img-fluid' />
                            <p className='copyright'>Copyright 2022.<br/>
                                All Rights Reserved.</p>

                        </div>
                        <div className='col-md-4'>
                            <h3>ABADI CEMERLANG</h3>
                            <p>
                                Ruko Sentra Menteng No. MN-45<br/>
                                Jl. Samratulangi<br/>
                                Bintaro Sektor 7<br/>
                                Tangerang Selatan 15224<br/>
                                Indonesia
                            </p>
                        </div>
                        <div className='col-md-4'>
                            <h4>CONTACT US</h4>
                            <ul>
                                <li>
                                    <a href='tel:628139801898'>
                                        <PhoneAndroidIcon /> +62 8139801898
                                    </a>
                                </li>
                                <li>
                                    <a href='tel:622174860595'>
                                        <PhoneIcon /> +62 21 74860595 (Hunting)
                                    </a>
                                </li>
                                <li>
                                    <PrintIcon /> +62 21 74861744
                                </li>
                                <li>
                                    <a href='mailto:abadi_cemerlang@abadisurvey.com'>
                                        <EmailIcon /> abadi_cemerlang@abadisurvey.com
                                    </a>
                                </li>

                            </ul>
                            <div className='d-flex justify-content-start social '>
                                <h4 className='mb-0 mr-4'>FOLLOW US  </h4>
                                <a href='https://www.linkedin.com/company/pt-abadi-cemerlang/' target='_blank'>
                                    <LinkedInIcon />
                                </a>
                            </div>

                        </div>
                    </div>

                </div>
            </footer>
        )

    }

}
export default Footer;